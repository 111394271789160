import React, { forwardRef } from 'react';
import styles from "./styles.module.scss";

interface ButtonProps {
    children: string;
    width?: string;
    height?: string;
    fontSize?: string;
    borderRadius?: string;
    icon?: React.ReactNode;
    theme?: 'primary' | 'secondary' | 'danger' | 'close';
    onClick?: () => void;
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ children, width, height, fontSize, borderRadius, icon, theme = 'primary', onClick, type, disabled }, ref) => {
        const buttonClass = `${styles.button} ${theme === 'primary' ? styles.primary : theme === 'secondary' ? styles.secondary : theme === 'danger' ? styles.danger : theme === 'close' ? styles.close : ''} ${disabled ? styles.disabled : ''}`;

        return (
            <button
                ref={ref}
                className={buttonClass}
                style={{ width, height, fontSize, borderRadius }}
                onClick={onClick}
                type={type}
                disabled={disabled === true}
            >
                {icon && <span className={styles.icon}>{icon}</span>}
                {children}
            </button>
        );
    }
);

export default Button;
