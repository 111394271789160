import React, {ReactNode} from 'react';
import {Modal, ModalBody} from 'reactstrap';
import styles from './styles.module.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

interface ModalConfirmProps {
    children: ReactNode;
    propsFunction: () => void;
    open: boolean;
    setOpen: (open: boolean) => void;
    loading: boolean;
    nomeButton?: string;
    disabled?: boolean;
}

const ModalConfirm: React.FC<ModalConfirmProps> = ({
                                                       children,
                                                       propsFunction,
                                                       open,
                                                       setOpen,
                                                       loading,
                                                       nomeButton,
                                                       disabled = false,
                                                   }) => {
    const toggle = () => setOpen(false);

    return (
        <Modal isOpen={open} toggle={toggle} className={styles.modalContent}>
            <ModalBody className={styles.modalBody}>
                <span className={styles.close} onClick={() => setOpen(!open)}>&times;</span>
                {children}
                <div className={styles.btnConfirm}>
                    <button onClick={() => setOpen(!open)} disabled={loading}>
                        Cancelar
                    </button>
                    <button onClick={() => propsFunction()} disabled={loading || disabled}>
                        {loading ? (
                            <>
                                <span>Aguarde...</span>
                            </>
                        ) : (
                            nomeButton ?? 'Sim'
                        )}
                    </button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ModalConfirm;
