import React, {useState} from "react";
import {useQuery} from "react-query";
import {getNotifications, showViewNotification} from "../../../services/services";
import styles from "../../Notifications/Notifications/styles.module.scss";
import {Helmet} from "react-helmet";
import {ErrorMessage, Field, Form, Formik} from "formik";
import Button from "../../../components/Button/Button";
import {BarLoader} from "react-spinners";
import Pagination from "../../../components/Pagination/Pagination";
import {IGetNotificationResponse, IGetNotifications} from "../../../ts/interfaces/Notification/interface";
import Select from "../../../components/Select/Select";
import DateRangePicker from "../../../components/Date/DateRangePicker";
import {adjustUtcDate, formatNotificationData, getUserName, limitString} from "../../../helper/formatters";
import StatusButton from "../../../components/Button/StatusButton";


const Notifications: React.FC = () => {

    const [notificationId, setNotificationId] = useState<number | null>(null);
    const [isShowModal, setIsShowModal] = useState(false);
    const [index, setIndex] = useState<number | null>(null);
    const [filter, setFilter] = useState<IGetNotifications>({
        subject: "",
        status: "",
        startAt: "",
        endAt: "",
        page: 1,
        limit: 50,
    });

    const {
        data,
        isLoading
    } = useQuery<IGetNotificationResponse>(["notifications", filter], () => getNotifications(filter), {
        staleTime: Infinity,
    });

    const { data: notificationView, isLoading: loadingViewNotification } = useQuery(
        ["notification", notificationId],
        () => showViewNotification(notificationId ?? 0),
        { enabled: !!notificationId }
    );

    // @ts-ignore
    return (
        <div className={styles.container}>
            <Helmet>
                <title>Notificações</title>
            </Helmet>
            <h1>Notificações</h1>
            <Formik
                initialValues={{
                    subject: filter.subject,
                    status: filter.status,
                    startAt: filter.startAt,
                    endAt: filter.endAt,
                }}
                onSubmit={(values) => {
                    setFilter({
                        ...filter,
                        subject: values.subject,
                        status: values.status,
                        startAt: values.startAt,
                        endAt: values.endAt,
                        page: 1,
                    });
                }}
            >
                {({values, setFieldValue}) => (
                    <Form className={styles.searchProducts}>
                        <div className={styles.alignInput}>
                            <Field
                                name="subject"
                                type="text"
                                placeholder="Pesquisar por assunto"
                                className={styles.input}
                                value={values.subject}
                            />
                            <ErrorMessage name="subject" component="div" className={styles.error}/>

                            <Select
                                value={values.status}
                                onChange={(e) => setFieldValue("status", e.target.value)}
                            >
                                <option value="" disabled>Status</option>
                                <option value="pending">Pendente</option>
                                <option value="sent">Enviado</option>
                                <option value="error">Erro</option>
                            </Select>
                            <ErrorMessage name="status" component="div" className={styles.error}/>

                            <DateRangePicker
                                startDate={values.startAt}
                                endDate={values.endAt}
                                onDateChange={(start, end) => {
                                    setFieldValue("startAt", start);
                                    setFieldValue("endAt", end);
                                }}
                            />

                        </div>
                        <div className={styles.alignButtonSearch}>
                            <Button
                                type="submit"
                                width="115px"
                                height="39px"
                                theme="primary"
                                fontSize="14px"
                                disabled={isLoading}
                            >
                                Pesquisar
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>

            <div className={styles.groupCount}>
                <div className={styles.alignCount}>
                    <p>Total</p>
                    <span>{data && data.data ? data.data.total : '0'}</span>
                </div>
                <div className={styles.alignCount}>
                    <p>Pendente</p>
                    <span>{data && data.data ? data.data.totalPending : '0'}</span>
                </div>
                <div className={styles.alignCount}>
                    <p>Enviado</p>
                    <span>{data && data.data ? data.data.totalSent : '0'}</span>
                </div>
                <div className={styles.alignCount}>
                    <p>Erro</p>
                    <span>{data && data.data ? data.data.totalError : '0'}</span>
                </div>
            </div>
            <div className={styles.tableProducts}>
                {isLoading ? (
                    <div className={styles.alignCenter}>
                        <span>Carregando</span>
                        <BarLoader
                            color={"#2b6cded9"}
                            loading={isLoading}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                ) : (
                    (data?.data?.notifications?.length ?? 0) > 0 ? (
                        <table>
                            <thead>
                            <tr>
                                <th>Assunto</th>
                                <th>Mensagem</th>
                                <th>Status</th>
                                <th>Criado Por</th>
                                <th>Emails</th>
                                <th>Criado</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {data && data.data.notifications.map((notification, idx) => (
                                <tr key={notification.id}>
                                    <td>{notification.subject}</td>
                                    <td className={styles.message}>{limitString(formatNotificationData(notification.message))}</td>
                                    <td><StatusButton label={notification.status_label}/></td>
                                    <td>{getUserName(notification.message)}</td>
                                    <td>
                                        {notification.emails.length > 1
                                            ? `${notification.emails.length} emails`
                                            : "1 email"
                                        }
                                    </td>
                                    <td>{adjustUtcDate(notification.created_at)}</td>
                                    <td>
                                        <Button
                                            type="submit"
                                            width="80px"
                                            height="39px"
                                            theme="primary"
                                            fontSize="14px"
                                            disabled={isLoading}
                                            onClick={() => {
                                                setIndex(idx);
                                                setIsShowModal(true);
                                                setNotificationId(notification.id);
                                            }}
                                        >
                                            Detalhes
                                        </Button>
                                    </td>

                                </tr>
                            ))}
                            </tbody>
                        </table>
                    ) : (
                        <div>Nenhuma notificação encontrada.</div>
                    )
                )}
            </div>

            {isShowModal && (
                <div
                    onClick={(e) => {
                        if (e.target === e.currentTarget) {
                            setIsShowModal(false);
                            setIndex(null);
                        }
                    }}
                    className={styles.modal}
                >
                    <div className={styles.modalContent}>
            <span
                className={styles.close}
                onClick={() => {
                    setIsShowModal(false);
                    setIndex(null);
                }}
            >
                &times;
            </span>

                        {loadingViewNotification ? (
                            <div className={styles.alignCenter}>
                                <span>Carregando</span>
                                <BarLoader
                                    color={"#2b6cded9"}
                                    loading={loadingViewNotification}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </div>
                        ) : (
                            <>
                                <h2>Detalhes Notificação</h2>

                                <div className={styles.infoItem}>
                                    <h3>Assunto:</h3>
                                    <p>{index !== null ? data?.data.notifications[index].subject : ""}</p>
                                </div>

                                <div className={styles.infoItem}>
                                    <h3>Mensagem:</h3>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: notificationView?.data || "",
                                        }}
                                    />
                                </div>

                                <div className={styles.groupInfo}>
                                    <div className={styles.infoItem}>
                                        <h3>Status</h3>
                                        <p>{index !== null ? data?.data.notifications[index].status_label : ""}</p>
                                    </div>

                                    <div className={styles.infoItem}>
                                        <h3>Data de Registro:</h3>
                                        <p>{index !== null ? adjustUtcDate(data?.data.notifications[index].created_at ?? "") : ""}</p>
                                    </div>
                                </div>

                                <div className={styles.infoItem}>
                                    <h3>Criado Por</h3>
                                    <p>{index !== null ? getUserName(data?.data.notifications[index].message ?? "") : ""}</p>
                                </div>

                                <div className={styles.infoItem}>
                                    <h3>Emails Registrados:</h3>
                                    {index !== null &&
                                        data?.data.notifications[index].emails.map(
                                            (email: any, idx) => (
                                                <div key={idx}>
                                                    <p>{email.email}</p>
                                                </div>
                                            )
                                        )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}


            {data && (
                <Pagination
                    currentPage={data.data.currentPage}
                    lastPage={data.data.lastPage}
                    onPageChange={(payload: number) =>
                        setFilter({...filter, page: payload})
                    }
                />
            )}
        </div>
    );
}

export default Notifications;