import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import Button from "../../components/Button/Button";
import Select from "../../components/Select/Select";
import DateRangePicker from "../../components/Date/DateRangePicker";
import {IGetProductResponse, IGetProducts} from "../../ts/interfaces/Product/interface";
import {useQuery} from "react-query";
import {getProducts} from "../../services/services";
import {BarLoader} from "react-spinners";
import Pagination from "../../components/Pagination/Pagination";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import {adjustUtcDate} from "../../helper/formatters";

const Product: React.FC = () => {

    const navigate = useNavigate();

    const [filter, setFilter] = useState<IGetProducts>({
        sku: "",
        status: "",
        edition: "",
        startAt: "",
        endAt: "",
        page: 1,
        limit: 50,
    });

    const {data, isLoading} = useQuery<IGetProductResponse>(["product", filter], () => getProducts(filter), {
        staleTime: Infinity,
    });

    return (
        <div className={styles.container}>
            <Helmet>
                <title>Produtos</title>
            </Helmet>
            <h1>Produtos</h1>
            <Formik
                initialValues={{
                    sku: filter.sku,
                    status: filter.status,
                    edition: filter.edition,
                    startAt: filter.startAt,
                    endAt: filter.endAt,
                }}
                onSubmit={(values) => {
                    setFilter({
                        ...filter,
                        sku: values.sku,
                        status: values.status,
                        edition: values.edition,
                        startAt: values.startAt,
                        endAt: values.endAt,
                        page: 1,
                    });
                }}
            >
                {({values, setFieldValue}) => (
                    <Form className={styles.searchProducts}>
                        <div className={styles.alignInput}>
                            <Field
                                name="sku"
                                type="text"
                                placeholder="Pesquisar por SKU"
                                className={styles.input}
                                value={values.sku}
                            />
                            <ErrorMessage name="sku" component="div" className={styles.error}/>

                            <Select
                                value={values.status}
                                onChange={(e) => setFieldValue("status", e.target.value)}
                            >
                                <option value="" disabled>Status</option>
                                <option value="1">Habilitado</option>
                                <option value="2">Desabilitado</option>
                            </Select>

                            <Select
                                value={values.edition}
                                onChange={(e) => setFieldValue("edition", e.target.value)}
                            >
                                <option value="" disabled>Edição</option>
                                <option value="official">Oficial</option>
                                <option value="pre-sale">Pré-Venda</option>
                                <option value="preparatory">Preparatória</option>
                            </Select>

                            <DateRangePicker
                                startDate={values.startAt}
                                endDate={values.endAt}
                                onDateChange={(start, end) => {
                                    setFieldValue("startAt", start);
                                    setFieldValue("endAt", end);
                                }}
                            />
                        </div>
                        <div className={styles.alignButtonSearch}>
                            <Button
                                type="submit"
                                width="115px"
                                height="39px"
                                theme="primary"
                                fontSize="14px"
                                disabled={isLoading}
                            >
                                Pesquisar
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
            <div className={styles.alignButtonCsvRegister}>
                <Button
                    width="115px"
                    height="39px"
                    theme="secondary"
                    fontSize="14px"
                    onClick={() => navigate('/produto/cadastrar')}
                    disabled={isLoading}
                >
                    + Novo
                </Button>
            </div>

            <div className={styles.groupCount}>
                <div className={styles.alignCount}>
                    <p>Produtos ativos</p>
                    <span>{data && data.data ? data.data.total : '0'}</span>
                </div>
                <div className={styles.alignCount}>
                    <p>Produtos Pré-venda</p>
                    <span>{data && data.data ? data.data.totalPreSale : '0'}</span>
                </div>
                <div className={styles.alignCount}>
                    <p>Produtos Oficiais</p>
                    <span>{data && data.data ? data.data.totalOfficial : '0'}</span>
                </div>
                <div className={styles.alignCount}>
                    <p>Produtos Preparatórios</p>
                    <span>{data && data.data ? data.data.totalPreparatory : '0'}</span>
                </div>
            </div>
            <div className={styles.tableProducts}>
                {isLoading ? (
                    <div className={styles.alignCenter}>
                        <span>Carregando</span>
                        <BarLoader
                            color={"#2b6cded9"}
                            loading={isLoading}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                ) : (
                    (data?.data?.products?.length ?? 0) > 0 ? (
                        <table>
                            <thead>
                            <tr>
                                <th>Editora</th>
                                <th>Capa</th>
                                <th>SKU</th>
                                <th>Criado</th>
                                <th>Alterado</th>
                                <th>Nome</th>
                                <th>Tipo</th>
                                <th>Edição</th>
                                <th>Data Pré Venda </th>
                                <th>Status</th>
                                <th>Retorno PCI</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {data && data.data.products.map((product) => (
                                <tr key={product.id}>
                                    <td>{product.publisher}</td>
                                    <td>
                                        <img src={product.cover} alt="Capa" width={47} height={64}/>
                                    </td>
                                    <td className={styles.alignTd}>{product.sku}</td>
                                    <td>{adjustUtcDate(product.createdAt)}</td>
                                    <td>{adjustUtcDate(product.updatedAt)}</td>
                                    <td className={styles.alignTd}>{product.title}</td>
                                    <td>{product.type}</td>
                                    <td>{product.edition}</td>
                                    <td>{product.pre_sale_date ? product.pre_sale_date : ""}</td>
                                    <td>{product.status}</td>
                                    <td>{product.pciResponse}</td>
                                    <td>
                                        <Button
                                            width="90px"
                                            height="40px"
                                            onClick={() => navigate(`/produto/${product.id}`)}
                                        >
                                            Detalhes
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    ) : (
                        <div>Nenhum produto encontrado.</div>
                    )
                )}
            </div>
            {data && (
                <Pagination
                    currentPage={data.data.currentPage}
                    lastPage={data.data.lastPage}
                    onPageChange={(payload: number) =>
                        setFilter({...filter, page: payload})
                    }
                />
            )}
        </div>
    );
};

export default Product;