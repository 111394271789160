import React from "react";
import styles from "./styles.module.scss";
import { Field } from "formik";

interface DateRangePickerProps {
    startDate: string;
    endDate: string;
    onDateChange: (start: string, end: string) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({ startDate, endDate, onDateChange }) => {
    return (
        <div className={styles.dateRangePicker}>
            <Field
                type="date"
                value={startDate}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onDateChange(e.target.value, endDate)}
            />
            <span> até </span>
            <Field
                type="date"
                value={endDate}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onDateChange(startDate, e.target.value)}
            />
        </div>
    );
};

export default DateRangePicker;
