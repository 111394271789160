import AppRouter from './routes/Routes';
import {ToastContainer} from "react-toastify";


function App() {
    return (
        <>
            <AppRouter/>
            <ToastContainer/>
        </>
    );
}

export default App;
