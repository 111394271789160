import React from 'react';
import styles from "./styles.module.scss";

interface StatusButtonProps {
    label: string;
}

const StatusButton: React.FC<StatusButtonProps> = ({ label }) => {

    const buttonClass = `${styles.statusButton} ${label === 'Enviado' ? styles.sent : label === 'Pendente' ? styles.pending : label === 'Erro' ? styles.error  : ''}`;

    return (
        <button
            className={buttonClass}
        >
            {label}
        </button>
    );
};

export default StatusButton;
