import React, { useState } from 'react';
import styles from './styles.module.scss';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down.svg';

interface DropdownProps {
    children?: React.ReactNode;
    label: string;
    icon?: React.ReactNode;
    onClick?: () => void;
    className?: string; // Adicione a propriedade className aqui
}

const Dropdown: React.FC<DropdownProps> = ({ icon, label, children, className, onClick }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`${styles.dropdown} ${className ? className : ''}`}>
            <div className={`${styles.dropdownHeader} ${isOpen ? styles.dropdownContentOpen : ''}`} onClick={toggleDropdown}>
                {icon && <span className={`${styles.dropdownIcon} ${isOpen ? styles.iconOpen : ''}`}>{icon}</span>}
                <span className={styles.dropdownLabel}>{label}</span>
                <span className={styles.dropdownArrow}>{isOpen ? <ArrowUp/> : <ArrowDown />}</span>
            </div>
            {isOpen && (
                <ul className={styles.dropdownContent}>
                    {React.Children.map(children, (child) => (
                        <li onClick={onClick} className={styles.dropdownItem}>{child}</li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Dropdown;
