import axios from 'axios';
import { Cookies } from 'react-cookie';
import getCookie from '../helper/getCookie';

const cookies = new Cookies();

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

api.interceptors.request.use(async (config) => {
  const jwt_token = getCookie('jwt_token_pci');

  if (jwt_token && config && config.headers) {
    config.headers.Authorization = `Bearer ${jwt_token}`;
  }

  return config;
});

api.interceptors.response.use((config) => {
  return config;
}, error => {
  if (error.response.status === 401) {
    window.location = '/login' as any;
  }

  return error;
});

api.interceptors.response.use(async (config) => {
  const session_token = config.data?.session_token;
  const currentCookie = getCookie('session_token');

  if (!session_token) {
    return config;
  }

  if (session_token !== currentCookie) {
    localStorage.removeItem('@isOnlyDigitalProduct');
  }

  return config;
});

export default api;
