import React, { FC, useState, useEffect } from 'react';
import styles from "./styles.module.scss";
import { ReactComponent as Panel } from "../../assets/icons/painel.svg";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as ProductIcon } from "../../assets/icons/product.svg";
import { ReactComponent as Exit } from "../../assets/icons/exit.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
import { ReactComponent as NoticationIcon } from "../../assets/icons/bell.svg";
import { Outlet, useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { IGetUser } from "../../ts/interfaces/interface";
import Dropdown from "../Dropdown/Dropdown";
import Button from "../Button/Button";
import {useMutation} from "react-query";
import {getUser} from "../../services/services";
import logo from "../../assets/icons/logo.svg";

interface SidebarProps {}

const Sidebar: FC<SidebarProps> = () => {
    const [user, setUser] = useState<IGetUser | undefined>();
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(["jwt_token_pci"]);
    const [isCollapsed, setIsCollapsed] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 800) {
                setIsCollapsed(true);
            } else {
                setIsCollapsed(false);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const mutation = useMutation<IGetUser, Error>(() => getUser(), {
        onSuccess: (data) => {
            setUser(data);
        },
        onError: (error) => {
            console.error('Erro ao buscar nome do usuário:', error);
        }
    });

    useEffect(() => {
        mutation.mutate();
    }, []);


    function logout() {
        setCookie("jwt_token_pci", "", {
            domain: window.location.hostname,
            path: "/",
            maxAge: -1
        });
        localStorage.removeItem('jwt_token_pci');
        navigate("/");
    }

    return (
        <>
            <div className={`${styles.sidebar} ${isCollapsed ? styles.collapsed : ""}`}>
                <div className={styles.sidebarHeader}>
                    <div className={styles.alignTitle}>
                        <img className={styles.logo} src={logo} alt={'logo'}/>
                    </div>
                    <p>Olá {user?.data.name}</p>
                    <Button
                        width="100px"
                        height="25px"
                        theme="close"
                        fontSize="12px"
                        borderRadius="6px"
                        icon={<Exit />}
                        onClick={logout}
                    >
                        Sair
                    </Button>
                    <button className={styles.menuButton} onClick={() => setIsCollapsed(!isCollapsed)}>
                        <MenuIcon/>
                    </button>
                </div>

                <ul className={styles.sidebarMenu}>
                    <div className={styles.controlPanel} onClick={() => navigate('/')}>
                        <i><Panel /></i>
                        <span>Início</span>
                    </div>
                    <Dropdown className={styles.DropdownClose} label="Arquivos" icon={<ProductIcon/>}>
                        <li onClick={() => navigate('/produtos')}>Produtos</li>
                    </Dropdown>
                    <Dropdown className={styles.DropdownClose} label="Notificações" icon={<NoticationIcon/>}>
                        <li onClick={() => navigate('/notificacoes/emails')}>Gerenciar e-mails</li>
                        <li onClick={() => navigate('/notificacoes')}>Notificações</li>
                    </Dropdown>
                </ul>
            </div>
            <div className={`${styles.mainContainer} ${isCollapsed ? styles.expanded : ''}`}>
                <Outlet />
            </div>
        </>
    );
};

export default Sidebar;
