import React from 'react';
import styles from "./styles.module.scss";
import Button from "../../components/Button/Button";
import logo from "../../assets/icons/logo.svg";
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState} from 'react';
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {toast} from "react-toastify";
import {rememberPassword} from "../../services/services";

const validationSchema = Yup.object({
    email: Yup.string()
        .email('E-mail inválido')
        .required('Campo obrigatório')
});

const RememberPassword: React.FC = () => {

    const { executeRecaptcha } = useGoogleReCaptcha();

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    async function remember(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if(!email) {
            toast.error('E-mail obrigatório');
            return false;
        }

        let token = null;
        if(executeRecaptcha) {
            token = await executeRecaptcha('rememberPassword');
        }
        if(token) {
            setLoading(true);
            await rememberPassword({email: email, token: token});
            toast.success(`Se existir uma conta associada com ${email} você receberá um email com um link para redefinir sua senha.`, {autoClose: false})
            setLoading(false);
            setEmail("");
        } else {
            toast.error('Verifique o captcha');
        }
    }

    useEffect(() => {
        document.title = 'Recuperar senha';
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.alignTitle}>
                <img className={styles.logo} src={logo} alt={'logo'}/>
            </div>
            <div className={styles.align}>
                <Formik
                    initialValues={{
                        email: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        console.log(values);
                    }}
                >
                    {({ errors, touched }) => (
                        <Form onSubmit={(event) => remember(event)}>
                            <div className={styles.titleGroup}>
                                <h1>Recuperar senha</h1>
                                <span>Informe o seu e-mail de cadastro</span>
                            </div>
                            <div className={styles.inputContainer}>
                                <Field
                                    name="email"
                                    type="text"
                                    placeholder="E-mail"
                                    className={styles.inputText}
                                    value={email}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                                />
                                {/*<ErrorMessage name="email" component="div" className={styles.error}/>*/}
                            </div>
                            <Button
                                width="300px"
                                height="39px"
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? 'Carregando...' : 'Lembrar'}
                            </Button>
                            <a>Não lembro o e-mail</a>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

const TheRemember = () => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY ? process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY : ""}>
            <RememberPassword/>
        </GoogleReCaptchaProvider>
    )
}

export default TheRemember;
