import React from 'react';
import styles from "./styles.module.scss";
import {Helmet} from "react-helmet";

const Dashboard: React.FC = () => {
    return (
        <div className={styles.container}>
            <Helmet>
                <title>PCI Concursos</title>
            </Helmet>
            <h1>Início</h1>
        </div>
    );
};

export default Dashboard;
