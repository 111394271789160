import React, {useEffect} from "react";
import styles from "./styles.module.scss";
import Button from "../../../components/Button/Button";
import {ReactComponent as ArrowLeft} from "../../../assets/icons/arrow-left.svg";
import {useParams} from "react-router-dom";
import {
    IGetSku,
    IPostProduct,
    IProduct,
} from "../../../ts/interfaces/Product/interface";
import {useMutation, useQuery} from "react-query";
import {getProduct, getSku, getUrlS3, storeProduct} from "../../../services/services";
import {toast} from "react-toastify";
import ModalConfirm from "../../../components/ModalConfirm/ModalConfirm";
import {Helmet} from "react-helmet";
import {formatOnlyDate} from "../../../helper/formatters";

const CreateProduct = () => {
    const [formData, setFormData] = React.useState<FormData | null>(null);
    const queryParams = useParams();
    const searchButtonRef = React.useRef<HTMLButtonElement | null>(null);
    const [fileName, setFileName] = React.useState("");
    const [sku, setSku] = React.useState("");
    const [isPreSale, setIsPreSale] = React.useState(false);
    const [isReadMore, setIsReadMore] = React.useState(false);
    const [params, setParams] = React.useState<IPostProduct>({
        id: null,
        title: "",
        sku: "",
        type: "",
        edition: "",
        status: 1,
        price: 0,
        special_price: null,
        description: "",
        institution: null,
        occupation: null,
        internal_id: 0,
        system_id: 0,
        url_summary: "",
        created_at_publisher: "",
        updated_at_publisher: "",
        url_cover: "",
        pre_sale_date: null,
        matters: [],
    });


    const {data, isLoading} = useQuery<{ data: IProduct }>(
        ["product", queryParams?.id],
        () => getProduct(parseInt(queryParams?.id ?? "0")),
        {
            staleTime: Infinity,
            enabled: !!queryParams?.id,
            onSettled: (response) => {
                if (!response?.data) {
                    window.location.href = "/produtos";
                }
            }
        }
    );


    useEffect(() => {
        if (data && data.data) {
            setParams({
                ...params,
                ...data.data,
            });
        }
    }, [data]);

    const handleChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setParams({...params, [e.target.name]: e.target.value});
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];

            const img = new Image();
            img.src = URL.createObjectURL(file);

            img.onload = () => {
                const isSize = img.width >= 800 && img.height >= 600;
                const isSolucaoSize = img.width === 552 && img.height === 762;

                if (isSize || isSolucaoSize) {
                    setParams({...params, [e.target.name]: file});
                    setFileName(file.name);
                } else {
                    toast.error("A imagem deve ter no mínimo 800px de largura e 600px de altura ou exatamente 552px de largura e 762px de altura.");
                }
                URL.revokeObjectURL(img.src);
            };
        }
    };

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();

        const errors: string[] = [];

        if (!params.pre_sale_date && !params.url_summary) {
            errors.push("Sumário: Não foi possível adicionar o produto porque o sumário está incompleto. Por favor, entre em contato com o responsável e tente novamente mais tarde.");
        }

        if (!params.pre_sale_date && params.matters.length === 0) {
            errors.push("Matérias: Não foi possível adicionar o produto porque as matérias estão incompletas. Por favor, entre em contato com o responsável e tente novamente mais tarde.");
        }

        if (!fileName) {
            errors.push("Capa: Adicione uma capa para o produto");
        }

        if (errors.length > 0) {
            errors.forEach((error) => toast.error(error));
            return;
        }

        const newFormData = new FormData();
        newFormData.append("id", queryParams?.id ?? "");
        newFormData.append("title", params.title);
        newFormData.append("sku", params.sku);
        newFormData.append("type", params.type);
        newFormData.append("edition", params.edition);
        newFormData.append("status", params.status.toString());
        newFormData.append("price", params.price.toString());
        newFormData.append("special_price", params.special_price?.toString() ?? "");
        newFormData.append("description", params.description);
        newFormData.append("institution", params.institution ?? "");
        newFormData.append("occupation", params.occupation ?? "");
        newFormData.append("internal_id", params.internal_id.toString());
        newFormData.append("system_id", params.system_id.toString());
        newFormData.append("created_at_publisher", params.created_at_publisher);
        newFormData.append("updated_at_publisher", params.updated_at_publisher);
        newFormData.append("pre_sale_date", params.pre_sale_date ?? "");
        newFormData.append("url_summary", params.url_summary ?? "");
        if (params.url_cover instanceof File) {
            newFormData.append("url_cover", params.url_cover);
        } else if (params.url_cover && params.url_cover !== null) {
            newFormData.append("url_cover", params.url_cover);
        }
        newFormData.append("matters", JSON.stringify(params.matters));
        setFormData(newFormData);
        if (params.pre_sale_date) {
            setIsPreSale(true);
        } else {
            if (formData) {
                mutate.mutate(formData);
            }
        }

    };

    const mutate = useMutation(
        (data: FormData) => {
            return storeProduct(data);
        },
        {
            onSettled: (response) => {
                if (response?.data?.success) {
                    toast.success("Salvo com sucesso!");
                    setTimeout(() => {
                        window.location.href = "/produtos";
                    }, 800);
                } else {
                    toast.error(
                        response?.data?.msg ?? "Erro ao salvar o produto"
                    );
                }
                setIsPreSale(false);
            },
            onError: (error) => {
                toast.error("Erro ao salvar o produto");
            },
        }
    );

    const {data: skuData, refetch: refetchSku} = useQuery<{ data: IGetSku }>(
        ["product-sku", sku],
        () => getSku(sku),
        {
            staleTime: Infinity,
            enabled: false,
            onError: (err) => {
                console.error("Erro ao buscar o SKU:", err);
                toast.error("SKU não encontrado.");
            },
            onSettled: (response) => {
                if (!response?.data?.product) {
                    toast.error(response?.data?.msg ?? "SKU não encontrado.");
                }
            },
        }
    );


    const [isLoadingSku, setIsLoadingSku] = React.useState(false);

    async function handleSku() {
        if (sku) {
            setIsLoadingSku(true);
            const response = await refetchSku();
            setIsLoadingSku(false);

            if (response.data?.data?.product) {
                setParams(response.data.data.product);
            } else {
                setParams({
                    id: null,
                    title: "",
                    sku: "",
                    type: "",
                    edition: "",
                    status: 1,
                    price: 0,
                    special_price: null,
                    description: "",
                    institution: null,
                    occupation: null,
                    internal_id: 1,
                    system_id: 1,
                    url_summary: "",
                    created_at_publisher: "",
                    updated_at_publisher: "",
                    pre_sale_date: null,
                    url_cover: "",
                    matters: [],
                });
            }
        }
    }

    const handleSkuKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (searchButtonRef.current) {
                searchButtonRef.current.click();
            }
        }
    };

    const getUrlS3Temporary = (url: string, system_id: number) => {
        getUrlS3({url, system_id}).then((response) => {
            window.open(response.data, "_blank", "noopener,noreferrer");
        })

    }

    return (
        <section className={styles.background}>
            <Helmet>
                <title>{queryParams.id ? "Editar Produto" : "Novo Produto"}</title>

            </Helmet>
            <div className={styles.container}>
                <div className={styles.title}>
                    <a href="/produtos" className={styles.btnReturn}>
                        <ArrowLeft/> Voltar
                    </a>
                    <h1>{queryParams.id ? "Editar" : "Novo"} Produto</h1>
                </div>

                <form onSubmit={onSubmit}>
                    <div className={styles.itemContainer}>
                        {!queryParams.id && (
                            <div className={styles.searchContainer}>
                                <input
                                    className={styles.skuInput}
                                    type="text"
                                    value={sku}
                                    onChange={({target}) =>
                                        setSku(target.value)
                                    }
                                    placeholder="Pesquisar por SKU"
                                    onKeyDown={handleSkuKeyDown}
                                />
                                <Button onClick={handleSku}
                                        type="button"
                                        disabled={isLoadingSku}
                                        ref={searchButtonRef}
                                >
                                    {isLoadingSku ? "Pesquisando..." : "Pesquisar"}
                                </Button>
                            </div>
                        )}
                        <h3>Dados Básicos</h3>
                        <div className={styles.groupData}>
                            <div className={styles.groupInput}>
                                <label htmlFor="sku">SKU</label>
                                <input
                                    type="text"
                                    id="sku"
                                    name="sku"
                                    value={params.sku}
                                    onChange={handleChange}
                                    disabled
                                />
                            </div>
                            <div className={styles.groupInput}>
                                <label htmlFor="title">Título</label>
                                <input
                                    type="text"
                                    id="title"
                                    name="title"
                                    value={params.title ?? ""}
                                    onChange={handleChange}
                                    disabled
                                />
                            </div>
                            <div className={styles.groupInput}>
                                <label htmlFor="institution">Instituição</label>
                                <input
                                    type="text"
                                    id="institution"
                                    name="institution"
                                    value={params.institution ?? ""}
                                    onChange={handleChange}
                                    disabled
                                />
                            </div>
                            <div className={styles.groupInput}>
                                <label htmlFor="occupation">Cargo</label>
                                <input
                                    type="text"
                                    id="occupation"
                                    name="occupation"
                                    value={params.occupation ?? ""}
                                    onChange={handleChange}
                                    disabled
                                />
                            </div>
                            <div className={styles.groupInput}>
                                <label htmlFor="created_at_publisher">
                                    Data Lançamento
                                </label>
                                <input
                                    type="date"
                                    id="created_at_publisher"
                                    name="created_at_publisher"
                                    value={formatOnlyDate(params.created_at_publisher)}
                                    onChange={handleChange}
                                    disabled
                                />
                            </div>
                            <div className={styles.groupInput}>
                                <label htmlFor="pre_sale_date">
                                    Data Pré Venda
                                </label>
                                <input
                                    type={params.pre_sale_date ? "date" : "text"}
                                    id="pre_sale_date"
                                    name="pre_sale_date"
                                    value={params.pre_sale_date ?? ""}
                                    onChange={handleChange}
                                    disabled
                                />
                            </div>
                            <div className={styles.groupInput}>
                                <label htmlFor="price">Preço</label>
                                <input
                                    type="text"
                                    id="price"
                                    name="price"
                                    value={`R$ ${(params.special_price ? Number(params.special_price).toFixed(2).replace('.', ',') : Number(params.price).toFixed(2).replace('.', ','))}`}
                                    onChange={handleChange}
                                    disabled
                                />
                            </div>
                            <div
                                className={`${styles.groupInput} ${styles.textArea}`}
                            >
                                <label htmlFor="description">Descrição</label>

                                {skuData?.data?.success || data?.data ? (
                                    <div className={styles.descriptionWrapper}>
                                        <div
                                            id="description"
                                            dangerouslySetInnerHTML={{__html: params.description}}
                                            className={`${styles.description} ${!isReadMore ? styles.collapsed : ''}`}

                                        />
                                        <button
                                            type="button"
                                            className={styles.expandButton}
                                            onClick={() => setIsReadMore(!isReadMore)}>{isReadMore ? "Ver menos" : "Ver mais"}</button>


                                    </div>
                                ) : null}

                            </div>
                        </div>
                    </div>

                    <div className={`${styles.itemContainer}`}>
                        <h3>Arquivos Matéria</h3>
                        {params.matters && params.matters.length > 0 ? (
                            params.matters.map((matter, index) => (
                                <div className={styles.materia} key={index}>
                                    <div className={styles.groupInput}>
                                        <label htmlFor="nome">
                                            Nome Matéria
                                        </label>
                                        <input
                                            type="text"
                                            id="nome"
                                            value={matter.title}
                                            disabled
                                        />
                                    </div>
                                    <div className={styles.groupInput}>
                                        <label htmlFor="paginas">Páginas</label>
                                        <input
                                            type="number"
                                            id="paginas"
                                            value={matter.pages}
                                            disabled
                                        />
                                    </div>
                                    {typeof matter.url === "string" && (
                                        <a onClick={() => getUrlS3Temporary(matter.url ? matter.url?.toString() : "", params.system_id ?? 0)}
                                           target="_blank" rel="noopener noreferrer">{matter.url}</a>
                                    )}
                                </div>
                            ))
                        ) : (
                            <p>A apostila não possui matérias</p>
                        )}
                    </div>

                    <div className={`${styles.itemContainer}`}>
                        <h3>Arquivo Sumário Completo</h3>
                        <div className={styles.sumario}>
                            {params.url_summary ? (
                                <a onClick={() => getUrlS3Temporary(params.url_summary ? params.url_summary.toString() : "", params.system_id ?? 0)}
                                   target="_blank" rel="noopener noreferrer">
                                    {params.url_summary}
                                </a>
                            ) : (
                                <p>A apostila não possui sumário</p>
                            )}
                        </div>
                    </div>

                    <div className={`${styles.itemContainer}`}>
                        <h3>Arquivos Capa</h3>
                        <div className={styles.capa}>
                            <div className={styles.file}>
                                <input
                                    type="file"
                                    accept="image/*"
                                    name="url_cover"
                                    onChange={handleFileChange}
                                />
                                Adicionar Capa
                            </div>
                            {fileName && <p>{fileName}</p>}
                            {queryParams.id && typeof params.url_cover === "string" && (
                                <a href={params.url_cover} target="_blank" rel="noopener noreferrer">
                                    {params.url_cover}
                                </a>
                            )}
                        </div>
                    </div>

                    <div className={styles.saveButton}>
                        <Button
                            type="submit"
                            fontSize="1.2rem"
                            width="150px"
                            disabled={mutate.isLoading}
                        >
                            {mutate.isLoading ? "Salvando..." : "Salvar"}
                        </Button>
                    </div>
                </form>
                {isPreSale && formData && (
                    <ModalConfirm
                        propsFunction={() => mutate.mutate(formData)}
                        open={isPreSale}
                        setOpen={setIsPreSale}
                        loading={mutate.isLoading}
                    >
                        <span>A apostila está em pré-venda!</span>
                        <span>Tem certeza que deseja salvar?</span>
                    </ModalConfirm>
                )}

            </div>
        </section>
    );
};

export default CreateProduct;
