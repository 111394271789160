import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {useMutation, useQuery} from "react-query";
import {deleteEmail, getEmails, storeEmail} from "../../../services/services";
import styles from "../../Notifications/Email/styles.module.scss";
import {Helmet} from "react-helmet";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {queryClient} from "../../../services/queryClient";
import Button from "../../../components/Button/Button";
import {BarLoader} from "react-spinners";
import Pagination from "../../../components/Pagination/Pagination";
import {IGetEmailResponse, IGetEmails, IPostEmail} from "../../../ts/interfaces/Email/interface";
import {toast} from "react-toastify";
import {ReactComponent as Trash} from "../../../assets/icons/trash.svg";
import {ReactComponent as Pencil} from "../../../assets/icons/pencil.svg";

import ModalConfirm from "../../../components/ModalConfirm/ModalConfirm";
import {adjustUtcDate} from "../../../helper/formatters";


const Email: React.FC = () => {
    const navigate = useNavigate();
    const [isShowModal, setIsShowModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [id, setId] = useState<number | null>(null);
    const [index, setIndex] = useState<number | null>(null);
    const [filter, setFilter] = useState<IGetEmails>({
        name: "",
        email: "",
        page: 1,
        limit: 50,
    });

    const {data, isLoading} = useQuery<IGetEmailResponse>(["emails", filter], () => getEmails(filter), {
        staleTime: Infinity,
    });

    const mutate = useMutation(
        (data: IPostEmail) => {
            return storeEmail(data);
        },
        {
            onSettled: (response) => {
                if (response?.data?.success) {
                    toast.success("Salvo com sucesso!");
                    setTimeout(() => {
                        window.location.href = "/notificacoes/emails";
                    }, 800);
                } else {
                    toast.error(
                        response?.data?.msg ?? "Erro ao salvar o email"
                    );
                }
            },
            onError: (error) => {
                toast.error("Erro ao salvar o email");
            },
        }
    );

    const mutateDelete = useMutation(
        () => {
            return deleteEmail(id as number);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(["emails", filter]);
                toast.success('Deletado com sucesso');
                setOpen(false);
            },
            onError: () => {
                toast.error("Erro ao deletar o email");
            },
        }
    );

    return (
        <div className={styles.container}>
            <Helmet>
                <title>Gerenciar Emails</title>
            </Helmet>
            <h1>Gerenciar Emails</h1>
            <Formik
                initialValues={{
                    name: filter.name,
                    email: filter.email,
                }}
                onSubmit={(values) => {
                    setFilter({
                        ...filter,
                        name: values.name,
                        email: values.email,
                        page: 1,
                    });
                }}
            >
                {({values, setFieldValue}) => (
                    <Form className={styles.searchProducts}>
                        <div className={styles.alignInput}>
                            <Field
                                name="name"
                                type="text"
                                placeholder="Pesquisar por nome"
                                className={styles.input}
                                value={values.name}
                            />
                            <ErrorMessage name="name" component="div" className={styles.error}/>

                            <Field
                                name="email"
                                type="text"
                                placeholder="Pesquisar por email"
                                className={styles.input}
                                value={values.email}
                            />
                            <ErrorMessage name="email" component="div" className={styles.error}/>

                        </div>
                        <div className={styles.alignButtonSearch}>
                            <Button
                                type="submit"
                                width="115px"
                                height="39px"
                                theme="primary"
                                fontSize="14px"
                                disabled={isLoading}
                            >
                                Pesquisar
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
            <div className={styles.alignButtonCsvRegister}>
                <Button
                    width="115px"
                    height="39px"
                    theme="secondary"
                    fontSize="14px"
                    onClick={() => {
                        setId(null);
                        setIsShowModal(true);
                    }}
                    disabled={isLoading}
                >
                    + Adicionar
                </Button>
            </div>

            <div className={styles.groupCount}>
                <div className={styles.alignCount}>
                    <p>Emails ativos</p>
                    <span>{data && data.data ? data.data.total : '0'}</span>
                </div>
            </div>
            <div className={styles.tableProducts}>
                {isLoading ? (
                    <div className={styles.alignCenter}>
                        <span>Carregando</span>
                        <BarLoader
                            color={"#2b6cded9"}
                            loading={isLoading}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                ) : (
                    (data?.data?.emails?.length ?? 0) > 0 ? (
                        <table>
                            <thead>
                            <tr>
                                <th>Nome</th>
                                <th>E-mail</th>
                                <th>Criado</th>
                                <th></th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {data && data.data.emails.map((email, idx) => (
                                <tr key={email.id}>
                                    <td>{email.name}</td>
                                    <td>{email.email}</td>
                                    <td>{adjustUtcDate(email.createdAt)}</td>
                                    <td>
                                        <button
                                            className={
                                                styles.editEmail
                                            }
                                            onClick={() => {
                                                setIndex(idx);
                                                setIsShowModal(true)
                                            }}
                                        >
                                            <Pencil/>
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            onClick={() => {
                                                setId(email.id);
                                                setOpen(true);
                                            }}
                                            className={
                                                styles.deleteEmail
                                            }
                                        >
                                            <Trash/>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    ) : (
                        <div>Nenhum email encontrado.</div>
                    )
                )}
            </div>

            {isShowModal && (
                <div onClick={(e) => {
                    if (e.target === e.currentTarget) {
                        setIsShowModal(false);
                        setIndex(null);
                    }
                }} className={styles.modal}>
                    <div className={styles.modalContent}>
                        <span className={styles.close} onClick={() => {
                            setIsShowModal(false);
                            setIndex(null)
                        }}>
                            &times;
                        </span>
                        <h2>{index !== null ? "Editar" : "Cadastrar"} Email</h2>
                        <Formik
                            initialValues={{
                                id: index !== null ? data?.data.emails[index].id : null,
                                name: index !== null ? data?.data.emails[index].name || "" : "",
                                email: index !== null ? data?.data.emails[index].email || "" : "",
                            }}
                            enableReinitialize={true}
                            onSubmit={(values) => {
                                mutate.mutate(values);
                            }}
                        >
                            <Form className={styles.formModal}>
                                <div className={styles.alignInput}>
                                    <label htmlFor="name">Nome</label>
                                    <Field
                                        name="name"
                                        type="text"
                                        required
                                        placeholder="Nome"
                                        className={styles.input}
                                    />
                                    <ErrorMessage name="name" component="div" className={styles.error}/>
                                </div>
                                <div className={styles.alignInput}>
                                    <label htmlFor="email">Email</label>
                                    <Field
                                        name="email"
                                        type="email"
                                        required
                                        placeholder="email@email.com"
                                        className={styles.input}
                                    />
                                    <ErrorMessage name="email" component="div" className={styles.error}/>
                                </div>
                                <div className={styles.alignButton}>
                                    <Button

                                        theme="primary"
                                        fontSize="14px"
                                        type="submit"
                                    >
                                        Salvar
                                    </Button>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            )}

            {open && id && (
                <ModalConfirm
                    propsFunction={mutateDelete.mutate}
                    open={open}
                    setOpen={setOpen}
                    loading={mutateDelete.isLoading}
                >
                    <span>Essa ação é irreversível!</span>
                    <span>Tem certeza que deseja deletar o email?</span>
                </ModalConfirm>
            )}

            {data && (
                <Pagination
                    currentPage={data.data.currentPage}
                    lastPage={data.data.lastPage}
                    onPageChange={(payload: number) =>
                        setFilter({...filter, page: payload})
                    }
                />
            )}
        </div>
    );
}

export default Email;